import React from 'react';
import { kebabCase, lowerCase, uniq, startCase } from 'lodash';
import { graphql, Link, useStaticQuery } from 'gatsby';
//import styled from 'styled-components';

/*
const NavLink = styled.li`
  :hover {
    a {
      background: transparent !important;
      opacity: 0.7;
    }
  }

  a > span {
    line-height: 16px;
    text-align: right;
    padding: 15px 24px 15px 13px;
    margin: 0 0px;
    white-space: nowrap;
    color: #222323;
    text-transform: uppercase;
    border-bottom: 0px solid #ddd;
    border-radius: 0;
  }
`;
*/

export const Header = ({ isVip = false }: { isVip?: boolean }): any => {
  const {
    allProductsCsv: { edges }
  } = useStaticQuery(graphql`
    query navCollectionsQuery {
      allProductsCsv {
        edges {
          node {
            Barcode
            Handle
            WholesalePrice
            WholesaleOnly
            Published
            SKU
            Sizes
            Tags
            Title
            Collection
            MainImage
            Image1
            Image2
            Image3
            Image4
            id
          }
        }
      }
    }
  `);

  const getCollectionsData = ({ isVip = false }: { isVip?: boolean }) => {
    return !isVip
      ? [
          'ALL',
          ...uniq(edges.map((edge: any) => edge.node.Collection))
            .sort()
            .reverse()
        ]
      : uniq(
          edges
            .filter((edge: any) => isVip && edge.node.WholesaleOnly !== 'Y')
            .map((edge: any) => edge.node.Collection)
        )
          .sort()
          .reverse();
  };

  const navOptions = getCollectionsData({ isVip });

  return (
    <header>
      <div className="wsmenucontainer clearfix">
        <div id="overlapblackbg"></div>
        <div className="wsmobileheader clearfix">
          <div className="smallogo">
            <Link style={{ marginLeft: '2rem' }} to="/" className="site-header__logo-image">
              <img
                className="lazyload js"
                src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_300x300.png?v=1563802389"
                data-src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_{width}x.png?v=1563802389"
                data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                data-aspectratio="11.010989010989011"
                data-sizes="auto"
                alt="GRAY MATTERS"
              />
              <noscript>
                <img
                  src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x.png?v=1563802389"
                  srcSet="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x.png?v=1563802389 1x, //cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x@2x.png?v=1563802389 2x"
                  alt="GRAY MATTERS"
                />
              </noscript>
            </Link>
          </div>
        </div>

        <div className="headerfull navigation" id="sticky-nav">
          <div className="wsmain">
            <div className="smllogo">
              <Link to="/" className="site-header__logo-image">
                <img
                  className="lazyload js"
                  src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_300x300.png?v=1563802389"
                  data-src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_{width}x.png?v=1563802389"
                  data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                  data-aspectratio="11.010989010989011"
                  data-sizes="auto"
                  alt="GRAY MATTERS"
                />

                <noscript>
                  <img
                    src="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x.png?v=1563802389"
                    srcSet="//cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x.png?v=1563802389 1x, //cdn.shopify.com/s/files/1/0012/4079/3193/files/logo_d372fcc9-f883-42eb-be88-ef6796c08b5b_220x@2x.png?v=1563802389 2x"
                    alt="GRAY MATTERS"
                  />
                </noscript>
              </Link>
            </div>
            <nav className="wsmenu clearfix">
              <ul className="mobile-sub wsmenu-list">
                <li className="mobile_menu_top_sapce">
                  <Link to="/collections/all" className="navtext">
                    <span></span>
                    <span>Collection</span>
                  </Link>
                  <ul className="wsmenu-submenu">
                    {navOptions.map((navOption: any, idx: number) => (
                      <li key={`nav-option-${idx}`}>
                        <Link to={`/collections/${kebabCase(navOption)}`}>
                          {startCase(lowerCase(navOption))}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="third_main_menu_li_mobile_only">
                  <a href="#" className="navtext">
                    <span></span>
                    <span>Language</span>
                    <span id="country_code">English</span>
                  </a>
                  <ul className="wsmenu-submenu email_sub_menu">
                    <li className="language-picker">
                      <span className="ly-custom-0"></span>
                    </li>
                  </ul>
                </li>
                {/*
                !isVip && (
                  <NavLink className="third_main_menu_li">
                    <Link to="/concept">
                      <span>Concept</span>
                    </Link>
                  </NavLink>
                )
                */}
              </ul>
            </nav>
          </div>

          <div id="top-search-bar" className="collapse" aria-expanded="true">
            <div className="container-fluid">
              <form action="/search" method="get" className="search_form_top" role="search">
                <input type="hidden" name="type" value="product" />
                <i className="icon-magnifier icons"></i>

                <input
                  className="search-header__input1 search__input1 form-control"
                  type="search"
                  name="q"
                  id="search_input2"
                  placeholder=""
                  aria-label="Search"
                />
                <i className="bling"></i>
                <span data-toggle="collapse" data-target="#top-search-bar" className="search-close">
                  ×
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
