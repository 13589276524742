import React, { ReactElement, useState } from 'react';

import {
  FooterRowContainer,
  FooterSectionContainer,
  FooterSectionLinksContainer,
  FooterSectionWrapper
} from './element';

const FooterSectionLinks = ({
  links = [],
  isOpen = false
}: {
  links?: { name: string, link: string }[],
  isOpen?: boolean
}) => {
  return (
    <FooterSectionLinksContainer isOpen={isOpen} className="FooterSection-links" id="panel">
      <ul>
        {links.map(({ name, link }: { name: string, link: string }, idx: number) => (
          <li key={`footer-link-${name}-${idx}`}>
            <a href={link}>{name}</a>
          </li>
        ))}
      </ul>
    </FooterSectionLinksContainer>
  );
};

const FooterSection = ({
  header = '',
  links = [],
  idx
}: {
  header?: string,
  links?: { name: string, link: string }[],
  idx: number
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FooterSectionContainer idx={idx} onClick={() => setIsOpen(!isOpen)}>
      <h4 className="icon_toggle" id="flip">
        <i className="fa fa-caret-right" aria-hidden="true"></i>
        {header}
      </h4>
      <FooterSectionLinks links={links} isOpen={isOpen} />
    </FooterSectionContainer>
  );
};

const footerSections = [
  {
    header: 'Company',
    links: [
      { name: 'Brand', link: 'https://graymattersnyc.com/pages/brand' },
      { name: 'Made In Italy', link: 'https://graymattersnyc.com/pages/made-in-italy' },
      { name: 'Brand Stories', link: 'https://graymattersnyc.com/pages/brand-stories' },
      { name: 'Press', link: 'https://graymattersnyc.com/pages/press' },
      { name: 'Terms & Conditions', link: 'https://graymattersnyc.com/pages/terms-conditions' },
      { name: 'Privacy Policy', link: 'https://graymattersnyc.com/pages/privacy-policy' }
    ]
  },
  {
    header: 'Client Services',
    links: [
      { name: 'Contact Us', link: 'https://graymattersnyc.com//pages/contact-us' },
      { name: 'Shoe Care', link: 'https://graymattersnyc.com/pages/shoe-care' }
    ]
  },
  {
    header: 'Connect',
    links: [
      { name: 'Instagram', link: 'https://www.instagram.com/graymattersnyc/?hl=en' },
      { name: 'Facebook', link: 'https://www.facebook.com/graymattersnyc' }
    ]
  }
];

export const Footer = (): ReactElement => {
  return (
    <div id="shopify-section-footer-new" className="shopify-section">
      <FooterSectionWrapper id="footer">
        <div className="foter_container">
          <FooterRowContainer className="row">
            {footerSections.map(
              (
                { header, links }: { header: string, links: { name: string, link: string }[] },
                idx: number
              ) => (
                <FooterSection
                  header={header}
                  links={links}
                  idx={idx}
                  key={`footer-section-${idx}`}></FooterSection>
              )
            )}
          </FooterRowContainer>
        </div>
      </FooterSectionWrapper>

      <footer className="">
        <div className="wsmain1">
          <div className="row copy_right_row">
            <div className="col-md-12">
              <ul className="list-inline copy_right_uline">
                <li>
                  <p>© 2020 Gray Matters</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
