module.exports = [{
      plugin: require('../plugins/password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"GRAYMATTERSNEWS","vipPassword":"GRAYMATTERS_VIP"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-44771495-2"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/build_4f5344a8/src/layout/SitewideLayout.tsx"},
    }]
