import React from 'react';
import {
  getSessionPassword,
  getQueryPassword,
  setSessionIsVip,
  isProtectedPage,
  deleteSessionIsVip
} from 'lib/auth';
import PasswordProtect from 'component/PasswordProtect';

const getPasswordCandidate = (location) => getQueryPassword(location) || getSessionPassword();

export const wrapPageElement = ({ props }, THEME_OPTIONS) => {
  const { password, vipPassword, pagePaths, partialMatching } = THEME_OPTIONS;
  const { location } = props;

  // password protection disabled
  if (!password && !vipPassword) {
    return;
  }

  // page-level protection
  const isPageLevelProtectionOn = pagePaths && pagePaths.length > 0;
  if (isPageLevelProtectionOn) {
    // non-protected page
    if (!isProtectedPage(location, pagePaths, partialMatching)) {
      return;
    }
  }

  // correct password
  const passwordCandidate = getPasswordCandidate(location);

  if ([password, vipPassword].includes(passwordCandidate)) {
    if (passwordCandidate === vipPassword) {
      setSessionIsVip();
    } else {
      deleteSessionIsVip();
    }
    return;
  }

  return <PasswordProtect />;
};
