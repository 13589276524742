// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-concept-tsx": () => import("./../../../src/pages/concept.tsx" /* webpackChunkName: "component---src-pages-concept-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-template-collection-tsx": () => import("./../../../src/template/collection.tsx" /* webpackChunkName: "component---src-template-collection-tsx" */),
  "component---src-template-product-tsx": () => import("./../../../src/template/product.tsx" /* webpackChunkName: "component---src-template-product-tsx" */)
}

