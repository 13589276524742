import React, { ReactElement, useState } from 'react';

import { setSessionPassword } from 'lib/auth';

import {
  PasswordProtectContainer,
  PasswordProtectForm,
  PasswordProtectInput,
  PasswordProtectButton
} from './element';

const WELCOME_MESSAGE = 'Welcome';
const ENTER_PASS_MESSAGE = 'Enter Password';

const PasswordProtect = (): ReactElement => {
  const [password, setPassword] = useState('');

  const onSubmit = (event: any) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <PasswordProtectContainer>
      <h1>{WELCOME_MESSAGE}</h1>
      <h4>{ENTER_PASS_MESSAGE}</h4>

      <PasswordProtectForm onSubmit={onSubmit}>
        <PasswordProtectInput
          name="password"
          type="password"
          value={password}
          onChange={(event: any) => setPassword(event.target.value)}
        />
        <PasswordProtectButton type="submit">Enter</PasswordProtectButton>
      </PasswordProtectForm>
    </PasswordProtectContainer>
  );
};

export default PasswordProtect;
