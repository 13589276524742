import styled from 'styled-components';

export const PasswordProtectContainer = styled.div`
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PasswordProtectForm = styled.form`
  width: 320px;
`;

export const PasswordProtectInput = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #cccccc;
`;

export const PasswordProtectButton = styled.button`
  width: 100%;
  height: 42px;
  background: #cccccc;
  color: #fff;
  border: 1px solid #cccccc;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: sans-serif;
  transition: color 500ms ease-in-out;

  background: #fff;
  color: #000;
  font-size: 11.5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-top: 20px;
  min-width: 180px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 0;
  display: inline-block;
  padding: 10px 35px;
  font-weight: 300;

  :hover {
    background: #000;
    color: #fff;
  }
`;
