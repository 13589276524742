import Cookies from 'js-cookie';
import queryString from 'query-string';

import { HistoryLocation } from '@reach/router';

const COOKIE_NAME = 'gm-auth';
const VIP_COOKIE_NAME = 'gm-auth-is-vip';

export const setSessionPassword = (passwordCandidate: string): string | undefined =>
  Cookies.set(COOKIE_NAME, passwordCandidate);

export const getSessionPassword = (): string | undefined => Cookies.get(COOKIE_NAME);

export const setSessionIsVip = (): string | undefined => Cookies.set(VIP_COOKIE_NAME, 'true');

export const getSessionIsVip = (): boolean => Cookies.get(VIP_COOKIE_NAME) === 'true';

export const deleteSessionIsVip = (): void => Cookies.remove(VIP_COOKIE_NAME);

export const getQueryPassword = (
  location: HistoryLocation
): string | string[] | null | undefined => {
  const { secret } = queryString.parse(location.search);
  return secret;
};

export const isProtectedPage = (
  { pathname }: { pathname: string },
  pagePaths: string[],
  partialMatching: boolean
): string | undefined => {
  const isProtected = pagePaths.find((path) => {
    const isIndexPage = pathname === '/';

    if (partialMatching && !isIndexPage) {
      return pathname.startsWith(path);
    }

    return path === pathname;
  });

  return isProtected;
};
