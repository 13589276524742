import styled from 'styled-components';

interface FooterSectionLinksProps {
  isOpen?: boolean;
}

export const FooterSectionLinksContainer =
  styled.div <
  FooterSectionLinksProps >
  `

  ul > li {
    line-height: 31px;
  }

  @media only screen and (max-width: 767px) {
    overflow: hidden;
    display: block;

    ul {
      padding: 15px 30px;
      list-style: none;
    }

    transition: height 800ms ease-in-out, opacity 500ms ease-in-out 200ms;
    ${({ isOpen = false }) => (isOpen ? `height: 200px; ` : `height: 0px; `)}

    ${({ isOpen = false }) =>
      isOpen ? `visibility: visible; opacity: 1;` : `visibility: hidden; opacity: 0`}
  }
`;

interface FooterSectionContainerProps {
  idx: number;
}

export const FooterSectionContainer =
  styled.div <
  FooterSectionContainerProps >
  `
  @media only screen and (min-width: 767px) {
    flex-grow: 1;
    flex-basis: 0;
    padding: ${({ idx }: { idx: number }) => (idx > 0 ? '0 15px 0 15px' : 0)};
    border-left: ${({ idx }: { idx: number }) => (idx > 0 ? '1px solid #d2d2d2' : 'none')};
  }
`;

export const FooterRowContainer = styled.div`
  @media only screen and (min-width: 767px) {
    display: flex;
  }
`;

export const FooterSectionWrapper = styled.section`
  @media only screen and (max-width: 767px) {
    padding: 1.5rem 0 1.5rem !important;
  }
`;
