import React from 'react';

export const SiteMetadata = (): any => {
  return (
    <>
      <link
        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap.min.css"
        rel="stylesheet"
      />

      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/theme.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/proxima_font.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/custom-menu.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/base.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/banners.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/country_popup.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/cart.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/checkout.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/collection.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/contact_form.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/cookie.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/currencies.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/footer.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/login.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/modal_dialog.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/my_account.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/navbar.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/pagefly.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/product.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/sidebar.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/sliders.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/wishlist.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/breadcrumbs.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/not_found.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/glider.min.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/modal-newsletter.scss.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Lato:400|Oswald:400|Roboto:400&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://cdn.pagefly.io/pagefly/2.12.7/css/main-style.css?t=898989898989898989898989"
      />
      <link
        rel="stylesheet"
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/pagefly.1b631905.scss.css?v=680222570174941307"
      />

      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/simple-icon.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/animate.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/flexslider.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/owl.carousel.min.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <link
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/owl.theme.default.min.css?v=898989898989898989898989"
        rel="stylesheet"
        type="text/css"
        media="all"
      />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Lato:400|Oswald:400|Roboto:400&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://cdn.pagefly.io/pagefly/2.13.4/css/main-style.css?t=20200810110814"
      />
      <link
        rel="stylesheet"
        href="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/pagefly.e7f43610.scss.css?v=898989898989898989898989"
      />
    </>
  );
};

export default SiteMetadata;
