import React, { ReactElement } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import SimpleReactLightbox from 'simple-react-lightbox';

import GlobalStyle from 'component/GlobalStyle';
import SiteMetadata from 'component/SiteMetadata';
import SitewideContainer from 'component/SitewideContainer';
import { checkForVip } from 'lib/permission';

interface Props {
  children: ReactElement;
  location?: any;
}

const transitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    opacity: 1,
    transition: `opacity 100ms ease-in-out`
  },
  exiting: {
    opacity: 0,
    transition: `opacity 250ms ease-in-out`
  }
};

const SitewideLayout = ({ children, location }: Props): any => {
  const isVip = checkForVip({ domain: location?.host });

  return (
    <>
      <SimpleReactLightbox>
        <GlobalStyle />
        <SiteMetadata />
        <SitewideContainer isVip={isVip}>
          <TransitionGroup component={null}>
            <Transition
              key={location.pathname}
              timeout={{
                enter: 100,
                exit: 100
              }}>
              {(status: any) => (
                <div style={{ ...transitionStyles[status] }}>
                  {React.cloneElement(children, { isVip })}
                </div>
              )}
            </Transition>
          </TransitionGroup>
        </SitewideContainer>
      </SimpleReactLightbox>
    </>
  );
};

export default SitewideLayout;
