import React, { ReactElement, ReactNode } from 'react';

import Footer from 'component/Footer';
import Header from 'component/Header';

interface Props {
  children: ReactNode | ReactNode[];
  isVip?: boolean;
}

export const SitewideContainer = ({ children, isVip = false }: Props): ReactElement => (
  <body className="new_body template-index" style={{ width: '100vw' }}>
    <a className="in-page-link visually-hidden skip-link" href="#MainContent">
      Skip to content
    </a>

    <div
      id="SearchDrawer"
      className="search-bar drawer drawer--top"
      role="dialog"
      aria-modal="true"
      aria-label="Search">
      <div className="search-bar__table">
        <div className="search-bar__table-cell search-bar__form-wrapper">
          <form className="search search-bar__form" action="/search" method="get" role="search">
            <input
              className="search__input search-bar__input"
              type="search"
              name="q"
              value=""
              placeholder="Search"
              aria-label="Search"
            />
            <button className="search-bar__submit search__submit btn--link" type="submit">
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-search"
                viewBox="0 0 37 40">
                <path d="M35.6 36l-9.8-9.8c4.1-5.4 3.6-13.2-1.3-18.1-5.4-5.4-14.2-5.4-19.7 0-5.4 5.4-5.4 14.2 0 19.7 2.6 2.6 6.1 4.1 9.8 4.1 3 0 5.9-1 8.3-2.8l9.8 9.8c.4.4.9.6 1.4.6s1-.2 1.4-.6c.9-.9.9-2.1.1-2.9zm-20.9-8.2c-2.6 0-5.1-1-7-2.9-3.9-3.9-3.9-10.1 0-14C9.6 9 12.2 8 14.7 8s5.1 1 7 2.9c3.9 3.9 3.9 10.1 0 14-1.9 1.9-4.4 2.9-7 2.9z" />
              </svg>
              <span className="icon__fallback-text">Submit</span>
            </button>
          </form>
        </div>
        <div className="search-bar__table-cell text-right">
          <button type="button" className="btn--link search-bar__close js-drawer-close">
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="icon icon-close"
              viewBox="0 0 40 40">
              <path
                d="M23.868 20.015L39.117 4.78c1.11-1.108 1.11-2.77 0-3.877-1.109-1.108-2.773-1.108-3.882 0L19.986 16.137 4.737.904C3.628-.204 1.965-.204.856.904c-1.11 1.108-1.11 2.77 0 3.877l15.249 15.234L.855 35.248c-1.108 1.108-1.108 2.77 0 3.877.555.554 1.248.831 1.942.831s1.386-.277 1.94-.83l15.25-15.234 15.248 15.233c.555.554 1.248.831 1.941.831s1.387-.277 1.941-.83c1.11-1.109 1.11-2.77 0-3.878L23.868 20.015z"
                className="layer"
              />
            </svg>
            <span className="icon__fallback-text">Close search</span>
          </button>
        </div>
      </div>
    </div>

    <div className="outer-container">
      <div id="shopify-section-header-new" className="shopify-section">
        <Header isVip={isVip} />
      </div>
      <div className="page-container" id="PageContainer">
        {children}
        <Footer />
      </div>
    </div>

    <ul hidden>
      <li id="a11y-refresh-page-message">choosing a selection results in a full page refresh</li>
      <li id="a11y-selection-message">press the space key then arrow keys to make a selection</li>
    </ul>

    <link rel="dns-prefetch" href="https://store.swymrelay.com" />
    <link rel="dns-prefetch" href="https:////swymprod.azureedge.net/code/swym-shopify.js" />
    <link rel="preconnect" href="https:////swymprod.azureedge.net/code/swym-shopify.js" />

    <div className="out_divvv">
      <div id="poup_for_redirection" className="popup1 hide">
        <div className="cnt223">
          <div className="custom-row row">
            <div className="pop-up-outer">
              <div className="col-md-6 col-sm-6 col-xs-12 padding_left_right_remove">
                <img
                  className="popup_image_new"
                  src="//cdn.shopify.com/s/files/1/0012/4079/3193/t/38/assets/popup_imag_n_1200x.png?v=17699726745486767235"
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="tagline">
                  <h3 className="walker_now">
                    Shipping to&nbsp;<span id="modal_country_name"></span>?
                  </h3>

                  <p id="modal_country_message">
                    By accessing our European site, you'll be able to browse and purchase a
                    selection of Gray Matters styles, priced in [[CURRENCY_SYMBOL]] and with no
                    additional duties.
                  </p>
                  <a id="swicthweb" className="dark-btn btn-block popup_dark_btn" href="/">
                    Take me to eu.graymattersnyc.com
                  </a>
                </div>
                <p className="popup-footnotes">
                  *Greenland, Iceland, Liechtenstein, Norway, Russia, Switzerland, Turkey may be
                  subject to import duties.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="modal fade" id="myModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h3></h3>
            <button type="button" className="close" data-dismiss="modal">
              ×
            </button>
          </div>

          <div className="modal-body">
            <div className="popup-form">
              <p>
                Thank you for interest in Gray Matters! Please leave your name, email address and
                desired shoe size, and we'll contact you with more information.
              </p>

              <div className="contact-form clearfix">
                <form
                  method="post"
                  action="/contact#ContactForm"
                  id="ContactForm"
                  acceptCharset="UTF-8"
                  className="contact-form">
                  <input type="hidden" name="form_type" value="contact" />
                  <input type="hidden" name="utf8" value="✓" />

                  <div className="col-md-6 input-field">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      id="ContactForm-name"
                      name="contact[First name]"
                      value=""
                    />
                  </div>
                  <div className="col-md-6 input-field">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      id="ContactForm-last_name"
                      name="contact[Last name]"
                      value="Last name"
                    />
                  </div>
                  <div className="col-md-6 input-field">
                    <input
                      type="email"
                      id="ContactForm-email"
                      className="form-control"
                      name="contact[email]"
                      autoCorrect="off"
                      placeholder="Email Address"
                      autoCapitalize="off"
                      value=""
                      aria-required="true"
                    />
                  </div>

                  <div className="col-md-6 input-field">
                    <select
                      name="contact[Size]"
                      id="ProductSelect-"
                      className="form-control product-form__variants no-js"
                      style={{ display: 'block' }}></select>

                    <input
                      className="hidden"
                      type="hidden"
                      id="ContactForm-product_name"
                      name="contact[Product Name]"
                      value=""
                    />
                  </div>

                  <div className="col-md-12 col-sm-12 input-field">
                    <input
                      type="submit"
                      className="soldout_form_btn dark-btn btn-block"
                      value="Send"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
);

export default SitewideContainer;
